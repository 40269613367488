import Vue from 'vue'
import Router from 'vue-router'
//import dashboardIndex from '@/views/dashboard/Index'
//import dashboard from '@/views/dashboard/Dashboard'
//import UserProfile from '@/views/dashboard/pages/UserProfile'
//import Notifications from '@/views/dashboard/component/Notifications'
//import icons from '@/views/dashboard/component/Icons'
//import Typography from '@/views/dashboard/component/Typography'
//import RegularTables from '@/views/dashboard/tables/RegularTables'
//import GoogleMaps from '@/views/dashboard/maps/GoogleMaps'
//import Upgrade from '@/views/dashboard/Upgrade'
//import Affinity from '@/views/dashboard/Affinity'
//import MailingType from '@/views/dashboard/MailingType'
//import MediaChannels from '@/views/dashboard/MediaChannels'
//import TempProduct from '@/views/dashboard/Temp'
//import ProcessProduct from '@/views/dashboard/ProcessProduct'
//import Audiences from '@/views/dashboard/Audiences'
//import Publications from '@/views/dashboard/Publications'
//import CommunicationType from '@/views/dashboard/CommunicationType'
//import Companies from '@/views/dashboard/Companies'
//import Provinces from '@/views/dashboard/Provinces'
// import Sectors from '@/views/dashboard/SectorManagement'
// import Logout from '@/views/dashboard/Logout'
// import ManageFileUpload from '@/views/dashboard/ManageFileUpload.vue'
// import ManageReports from '@/views/dashboard/ManageReports.vue'
//import ManageUsers from '@/views/dashboard/ManageUsers'
//import ManageRole from '@/views/dashboard/ManageRole'
//import UserPermissions from '@/views/dashboard/UserPermissions'
//import AddModule from '@/views/dashboard/AddModule'
// import ManageModule from '@/views/dashboard/ManageModule'
// import ManageModuleOperations from '@/views/dashboard/ManageModuleOperations'
// import productrouteconst from './plugins/productrouteconst'
// import ManageServices from '@/views/dashboard/ManageServices'
// import EndPointsManagement from '@/views/dashboard/EndPointsManagement'
//import ServiceEndpointPermission from '@/views/dashboard/ServiceEndpointPermission'
//import DirectMailProduct from '@/views/dashboard/DirectMailProduct'
//import DirectMailTemp from '@/views/dashboard/DirectMailTemp'
// import ManagePanel from '@/views/dashboard/ManagePanel'
// import UserLogsDetail from '@/views/dashboard/UserLogsDetail'
// import Deduplicates from '@/views/dashboard/Deduplicates'
// import Managedigital from '@/views/dashboard/Managedigital'
// import MobileDigitalTemp from '@/views/dashboard/MobileDigitalTemp'
// import MobileDigitalPublished from '@/views/dashboard/MobileDigitalPublished'
//import PrintMediaTemp from '@/views/dashboard/PrintMediaTemp'
//import PrintMediaPublished from '@/views/dashboard/PrintMediaPublished'
//import ProductArchieve from '@/views/dashboard/ProductArchieve'
// import ManageSection from '@/views/dashboard/ManageSection'
// import ManageProducerMail from '@/views/dashboard/ManageProducerMail'
// import ManageContactusMail from '@/views/dashboard/ManageContactusMail'
// import ManageBrokerMail from '@/views/dashboard/ManageBrokerMail'
// import RoleProductTaxonomy from '@/views/dashboard/RoleProductTaxonomy'
// import Dmduplicates from '@/views/dashboard/Dmduplicates'
// import PanelistScoreReport from '@/views/dashboard/PanelistScoreReport'
// import WebsiteUrlPublished from '@/views/dashboard/WebsiteUrlPublished'
// import WebsiteUrlTemp from '@/views/dashboard/WebsiteUrlTemp'
// import SocialMediaTemp from '@/views/dashboard/SocialMediaTemp'
// import SocailMediaPublished from '@/views/dashboard/SocailMediaPublished'
// import PanelistBagsManagement from '@/views/dashboard/PanelistBagsManagement'
// import ManageDmreport from '@/views/dashboard/ManageDmreport'
// import ApproveSender from '@/views/dashboard/ApproveSender'
// import UserTracker from '@/views/dashboard/UserTracker'
// import SidebarSorting from '@/views/dashboard/SidebarSorting'
// import MortgageScoreReport from '@/views/dashboard/MortgageScoreReport'
// import ProviderScoreReport from '@/views/dashboard/ProviderScoreReport'
// import RedisCache from '@/views/dashboard/RedisCache'
// import ComparePdf from '@/views/dashboard/ComparePdf'

Vue.use(Router)
Vue.prototype.$productrouteconst = () => import('./plugins/productrouteconst')


export default new Router({
  hashbang: false,
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Manage Affinity',
          path: 'affinity-admin',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Affinity'),
        },
        {
          name: 'Manage Sectors',
          path: 'sector',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/SectorManagement'),
        },
        {
          name: 'Manage Mailing Types',
          path: 'manageMType',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MailingType'),
        },
        {
          name: 'Manage MChannels',
          path: 'mChannel',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MediaChannels'),
        },

        {
          name: 'Unpublished Product- Electronic Content',
          path: 'temp-products',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Temp'),

        },
        {
          name: 'Published Product - Electronic Content',
          path: 'process-product',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ProcessProduct'),

        },
        {
          name: 'Unpublished Product - DirectMail',
          path: 'directm-temp-product',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/DirectMailTemp'),

        },
        {
          name: 'Published Product - DirectMail',
          path: 'directm-ProcessProduct',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/DirectMailProduct'),

        },
        {
          name: 'Unpublished Product - Print Media',
          path: 'print-media-unpublished',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/PrintMediaTemp'),

        },
        {
          name: 'published Product - Print Media',
          path: 'print-media-published',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/PrintMediaPublished'),

        },
        {
          name: 'Product Archive',
          path: 'product-archieve',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ProductArchieve')

        },
        {
          name: 'Audiences',
          path: 'audiences',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Audiences'),
        },
        {
          name: 'Publications',
          path: 'publications',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Publications'),
        },
        {
          name: 'Communication Type',
          path: 'communication',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/CommunicationType'),
        },
        {
          name: 'Companies',
          path: 'companies',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Companies'),
        },
        {
          name: 'States/Provinces',
          path: 'provinces',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Provinces'),
        },
        {
          name: 'Permission Management',
          path: 'permission-management/:id',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/UserPermissions'),
        },
        {
          name: 'Service Management',
          path: 'service-management/:id',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ServiceEndpointPermission'),
        },
        {
          name: 'Manage Users',
          path: 'manage-users',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageUsers'),
        },
        {
          name: 'Manage Role',
          path: 'manage-role',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageRole'),
        },
        {
          name: 'Manage Module',
          path: 'manage-modules',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageModule'),
        },
        {
          name: 'Add Module',
          path: 'add-module',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/AddModule'),
        },
        {
          name: 'Edit Module',
          path: 'edit-module/:id',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/AddModule'),
        },
        {
          name: 'Manage Services',
          path: 'manage-service',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageServices'),
        },
        {
          name: 'User Activity Logs',
          path: 'user-log/:name',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'User Activity Logs',
          path: 'user-log',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/UserLogsDetail'),
        },
        {
          name: 'Manage Endpoint',
          path: 'manage-endpoint',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/EndPointsManagement'),
        },
        {
          name: 'Manage Panel',
          path: 'panel',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManagePanel'),
        },
        {
          name: 'Manage Module Operations',
          path: 'manage-module-operations',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageModuleOperations'),
        },
        {
          name: 'Logout',
          path: 'logout',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Logout'),
        },
        {
          name: 'Manage Files',
          path: 'file-upload',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageFileUpload'),
        },
        
        {
          name: 'Manage Alert',
          path: 'alert-management',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/AlertManagement'),
        },

        {
          name: 'Manage Reports',
          path: 'manage-reports',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageReports'),
        },
        {
          name: 'Deduplicates',
          path: 'deduplicates',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Deduplicates'),
        },
        {
          name: 'Manage Mobile Digital',
          path: 'manage-mobile-digital',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Managedigital'),

        },
        {
          name: 'Mobile Digital Unpublished',
          path: 'mobile-digital-unpublished',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MobileDigitalTemp'),

        },
        {
          name: 'Mobile Digital Published',
          path: 'mobile-digital-published',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MobileDigitalPublished'),

        },
        {
          name: 'Product Taxonomy',
          path: 'role-product-taxonomy/:id',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/RoleProductTaxonomy'),

        }
        ,
        {
          name: 'Manage Section',
          path: 'section-management',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageSection'),

        }
        ,

        {
          name: 'Producer Enquiry',
          path: 'producer-mail',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageProducerMail'),

        }
        ,
        {
          name: 'Panelist Score',
          path: 'score-upload',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/PanelistScore'),

        },
        {
          name: 'Mortgage Broker Enquiry',
          path: 'brokermail',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageBrokerMail'),

        }
        ,
        {
          name: 'Contact us Enquiry',
          path: 'contactusmail',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageContactusMail'),

        },
        {
          name: 'Dmduplicates',
          path: 'dmduplicates',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/Dmduplicates'),
        },
        {
          name: 'Daily Status Report',
          path: 'dailystatus',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/DailyStatusReport'),
        },
        {
          name: 'Producer Scoring Monthly Report',
          path: 'panelist-score-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/PanelistScoreReport'),
        },
        {
          name: 'UnPublished- Website/Url',
          path: 'website-url-temp',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/WebsiteUrlTemp'),

        }
        ,
        {
          name: 'Published- Website/Url',
          path: 'website-url-published',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/WebsiteUrlPublished'),
        },
        {
          name: 'Published- Social Media',
          path: 'social-media-published',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/SocailMediaPublished'),
        },
        {
          name: 'UnPublished- Social Media',
          path: 'social-media-temp',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/SocialMediaTemp'),
        },
        {
          name: 'Panelist Bags Management',
          path: 'panelist-bags-management',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/PanelistBagsManagement'),
        },

        {
          name: 'Direct Mail Report',
          path: 'dmreport',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageDmreport'),

        },
        {
          name: 'Approve Sender Domain',
          path: 'approve-sender-list',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ApproveSender'),

        },
        {
          name: 'User Tracker',
          path: 'user-tracker',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/UserTracker'),

        },
        {
          name: 'Sidebar Sorting',
          path: 'sidebar-sorting',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/SidebarSorting'),

        },
        {
          name: 'Mortgage Broker Scoring Monthly Report',
          path: 'mortgage-broker-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MortgageScoreReport'),

        },
        {
          name: 'Provider Scoring Monthly Report',
          path: 'provider-score-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ProviderScoreReport'),

        },
        {
          name: 'Trend Report Management',
          path: 'manageTrends',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/TrendsReportManagement'),
          // component: () => import('@/views/dashboard/TrendSectorsTest'),

        },
        {
          name: 'Manage Redis Cache',
          path: 'redis-cache',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/RedisCache'),

        },
        {
          name: 'Compare PDF',
          path: 'compare_pdf/:id',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ComparePdf'),

        },
        {
          name: 'Pixel Report',
          path: 'pixel-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/PixelReport'),

        },
        {
          name: 'Consumer Scoring Report',
          path: 'consumer-score-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ConsumerScoreReport'),

        },
        {
          name: 'Consumer Scoring Monthly Report',
          path: 'consumer-score-monthly-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ConsumerScoreMonthlyReport'),

        },
        {
          name: 'Consumer Non Participation Monthly Report',
          path: 'consumer-non-participating-report',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/NonParticipatingPanelistReport'),

        },
        {
          name: 'Mail Volume',
          path: 'mail-volume',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MailVolume'),

        },
        {
          name: 'Merge Companies ',
          path: 'merge-company',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MergeCompanies'),

        },
        {
          name: 'Merge Affinities ',
          path: 'merge-affinities',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/MergeAffinities'),

        },
        {
          name: 'Manage Downloads',
          path: 'manage-downloads',
          title: 'Admin Portal - Competiscan.com',
          component: () => import('@/views/dashboard/ManageDownloads'),
        },

      ],
    },
  ],
})

